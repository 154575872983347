import React, { useState } from 'react'
import firebase from '../../components/Firebase'
import { useToasts } from 'react-toast-notifications'
import { Link, useNavigate } from 'react-router-dom'
import Button from '../../components/Button'
import './SignupScreen.css';
import { LOGIN } from '../../constants/routes'

function SignupScreen() {
	const [name, setName] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [working, setWorking] = useState(false);
	const { addToast } = useToasts();
	const navigate = useNavigate();

	return (
		<div className="center-container">
			<div className="narrow-page-container signup-container">

				<img className="logo-img" src="./images/foxy.png" alt="Logo of a cute fox."/>

				<h1>Listy.no</h1>

				<form>
					<input id="name" name="name"  placeholder="Name" autoFocus autoComplete="off" value={name} onChange={e => setName(e.target.value)} />
					<input id="phoneNumber" name="phoneNumber" placeholder="Phone number" autoComplete="off" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} />
					<input id="email" name="email" type="email" placeholder="Email" autoComplete="off" value={email} onChange={e => setEmail(e.target.value)}  />
					<input id="password" name="password" type="password" autoComplete="off" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)}  />
					<Button className="submit" type="submit" clickCallback={onSignup} buttonText="Sign up" working={working} />
				</form>

				<span className="secondary-link">Already a user? <Link to={LOGIN}>Log in</Link></span>
			</div>
		</div>
	);

	function onSignup(event) {
		event.preventDefault();
		setWorking(true);

		firebase.register(name.trim(), phoneNumber.trim(), email.trim(), password).then((data) => {
			addToast("Well done! We have sent you a verification email. Please verify your email address. It helps us remove all the fake accounts.", { appearance: 'success', autoDismiss: true, autoDismissTimeout: 5000});

			if(data === 'User created!') {
				navigate(LOGIN);
			}
		})
		.catch(function(error) {
			addToast(error.message, { appearance: 'error', autoDismiss: true});
			setWorking(false);
		});
	}
}

export default SignupScreen