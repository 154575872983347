import React, { useState } from 'react'
import firebase from '../../components/Firebase'
import { useToasts } from 'react-toast-notifications'
import { Link, useNavigate } from 'react-router-dom'
import Button from '../../components/Button'
import './ResetPasswordScreen.css';
import { LOGIN } from '../../constants/routes'

function ResetPasswordScreen() {
	const [email, setEmail] = useState('');
	const [working, setWorking] = useState(false);
	const { addToast } = useToasts();
	const navigate = useNavigate();

	return (
		<div className="center-container">
			<div className="narrow-page-container reset-password-container">

				<img className="logo-img" src="./images/foxy.png" alt="Logo of a cute fox."/>

				<h1>Trouble logging in?</h1>

				<span>Enter your email and we'll send you a link to reset your password.</span>

				<br />
				<br />

				<form>
					<input id="email" name="email" type="email" placeholder="Email" autoFocus autoComplete="off" value={email} onChange={e => setEmail(e.target.value)} />
					<Button className="submit" type="submit" clickCallback={resetPassword} buttonText="Send reset pasword email" working={working} />
				</form>

				<span className="secondary-link"><Link className="secondary" to={LOGIN}>Back to login</Link></span>
			</div>
		</div>
	);

	function resetCallback() {
		navigate(LOGIN);
	};

	async function resetPassword(event) {
		event.preventDefault();

		try {
			setWorking(true);
			await firebase.resetPassword(email.trim());
			addToast("Email with instruction how to reset password is sent to " + email, {
				appearance: 'success',
				autoDismiss: true,
				autoDismissTimeout: 8000
			}, resetCallback);
		} catch (error) {
			addToast("Unable to reset password. Are you sure '" + email + "' is registered?", {
				appearance: 'error',
				autoDismiss: true,
				autoDismissTimeout: 8000,
				placement: 'bottom-center'
			});
			setWorking(false);
		}
	}
}

export default ResetPasswordScreen