
import React, { useState, useEffect } from 'react';
import MainMenu from '../../components/MainMenu';
import { useToasts } from 'react-toast-notifications';
import { useSession } from '../../App';
import DeleteButton from '../../components/DeleteButton';
import Button from '../../components/Button'
import firebase from "../../components/Firebase";
import { useNavigate } from 'react-router-dom';
import {LOGIN} from '../../constants/routes';

import './ProfileScreen.scss';

function ProfileScreen() {
	const user = useSession();
	const [password, setPassword] = useState('');
	const [name, setName] = useState(user.displayName ? user.displayName : '');
	const [currentName, setCurrentName] = useState(name);
	const [nameIsEdited, setNameIsEdited] = useState(false);
	const [working, setWorking] = useState(false);
	const { addToast } = useToasts();
	const [emailVerified, setEmailVerified] = useState(user.emailVerified);
	const navigate = useNavigate();

	/*
	let photoURL = user.photoURL;
	if(!photoURL || photoURL == '') {
		const rand = Math.floor(Math.random() * 18) + 1;
		photoURL = "/images/avatars/512x512/avatar_" + rand + ".png";
		user.updateProfile({photoURL: photoURL});	
	}

	<div className="avatar-container">
		<img src={photoURL} />
	</div>
	*/
	
	useEffect(() => {
		setNameIsEdited(name.trim() !== '' && currentName !== name);
	  }, [name, currentName]);

	useEffect(() => {

		// Handy when verification has been done in a different browser than the curren tone
		user.reload().then(() => {
			setEmailVerified(firebase.getCurrentUser().emailVerified);
		});
	}, [user]);

	const changeName = () => {
		setWorking(true);
		user.updateProfile({
			displayName: name
		}).then(() => {
			setCurrentName(name);
		}).catch((error) => {
			console.log(error);
			addToast("Unable to update name. Please try a little later.", { appearance: 'error', autoDismiss: true });
		});
		setWorking(false);
	}

	const deleteHandler = () => {
		firebase.login(user.email, password).then(function() {
			user.delete().then(function() {
				addToast("It's done. You're free as a bird.", { appearance: 'success', autoDismiss: true });
				navigate(LOGIN);	
			}).catch(() => {
				addToast("Unable to delete account. Please try again later.", { appearance: 'error', autoDismiss: true }); 
		 	});
		}).catch(function() {
			addToast("The password is invalid", { appearance: 'error', autoDismiss: true });
		});
	};	

	const triggerVerificationEmail = (event) => {
		event.preventDefault();

		firebase.sendVerificationEmail().then(() => {
			addToast("We have sent an email where you can verify your address.", { appearance: 'success', autoDismiss: true });
		}).catch(() => {
			addToast("Unable to sende verification email. Please try again later.", { appearance: 'error', autoDismiss: true }); 
		})
	}

	let photoURL = "/images/avatars/512x512/avatar_" + user.uid.replace(/\D/g, "").toString().substr(0, 2) + ".png";
	
	return (<>
	    <MainMenu/>
		<div className="center-container-with-menu">
			<div className="narrow-page-container profile-page">
				<div className="avatar-container">
					<img src={photoURL} alt="Profile avatar"/>
				</div>
				<h2>Profile details</h2>
				<div className="profile-page-element">
					<span>Name:</span>
					<div><input id="name" name="name" type="text" autoComplete="off" value={name} onChange={e => setName(e.target.value)} />
					<Button type="submit" clickCallback={changeName} buttonText=" Save " working={working} disabled={ ! nameIsEdited}/></div>
				</div>

				<div className="profile-page-element">
					<span>Email {emailVerified ? '(verified)' : <a href="/" onClick={triggerVerificationEmail}> Verify email address</a>}:</span>
					<div><input id="email" name="email" type="text" disabled value={user.email}/></div>
				</div>

				<div className="delete-account">
					<h2>Delete account</h2>
					<input id="password" name="password" type="password" autoComplete="off" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)}  />
					<div className="delete-container"><DeleteButton buttonText="Delete account and all my lists" holdButtonText="Hold to delete" deleteHandler={() => { deleteHandler() }} /></div>
				</div>
			</div>
		</div>
	</>
	);
}

export default ProfileScreen