import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';

import { ToastProvider } from 'react-toast-notifications'
import * as serviceWorker from './serviceWorker';

import App from './App';

ReactDOM.render(
  <ToastProvider placement="top-center">
    <App />
  </ToastProvider>, 
  document.getElementById('content'),
);

serviceWorker.unregister();
