import React, { useState } from 'react'
import firebase from '../../components/Firebase'
import { useToasts } from 'react-toast-notifications'
import { Link } from 'react-router-dom'
import Button from '../../components/Button'
import './LoginScreen.css';
import {RESET_PASSWORD, SIGNUP} from '../../constants/routes'

function LoginScreen() {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [working, setWorking] = useState(false);
	const { addToast } = useToasts();

	return (
		<div className="center-container">
			<div className="narrow-page-container login-container">

				<img className="logo-img" src="./images/foxy.png" alt="Logo of a cute fox."/>

				<h1>Listy.no</h1>

				<form>
					<input id="email" name="email" type="email" placeholder="Email" autoFocus autoComplete="off" value={email} onChange={e => setEmail(e.target.value)} />
					<input id="password" name="password" type="password" autoComplete="off" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)}  />
					<Button className="submit" type="submit" clickCallback={login} buttonText=" Log in " working={working} />
				</form>

				<span className="secondary-link"><Link className="secondary" to={RESET_PASSWORD}>Forgot password?</Link></span>
				<span className="thirdary-link">Not using Listy yet? <Link to={SIGNUP}>Sign up</Link></span>
			</div>
		</div>
	);

	async function login(event) {
		event.preventDefault();

		try {
			setWorking(true);
			await firebase.login(email.trim(), password);
		} catch(error) {
			addToast(error.message, {
				appearance: 'error',
				autoDismiss: true,
			});
			setWorking(false);
		}
	}
}

export default LoginScreen