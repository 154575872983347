import React, { useState, useContext, useEffect, createContext } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import ListScreen from './screens/ListScreen';
import ListSettingsScreen from './screens/ListSettingsScreen';
import ListsScreen from './screens/ListsScreen';
import SignupScreen from './screens/SignupScreen';
import LoginScreen from './screens/LoginScreen';
import ResetPasswordScreen from './screens/ResetPasswordScreen';
import FirebaseEmailActionScreen from './screens/FirebaseEmailActionScreen';
import ProfileScreen from './screens/ProfileScreen';
import * as ROUTES from './constants/routes';
import firebase from './components/Firebase'
import Spinner from './components/Spinner';
import { useToasts } from 'react-toast-notifications';

const userContext = createContext({
  user: null,
})

export const useSession = () => {
  const { user } = useContext(userContext)
  return user
}

export const useAuth = () => {
  const [state, setState] = useState({ 
    initializing: true,
    user: null
  })
  
  function onChange(user) {
    setState({ 
      initializing: false, 
      user 
    })
  }

  useEffect(() => {
    const unsubscribe = firebase.setOnAuthStateChanged(onChange);
    return () => {
      unsubscribe();
    }
  }, [])

  return state;
}

export default function App() {
  const { initializing, user } = useAuth();
  const { addToast } = useToasts();

  if (initializing) {
    return <Spinner/>
  }

  function storeListIdInLocalStorage() {
    var listIds = [];
    const savedListIds = JSON.parse(localStorage.getItem('list_ids'));

    if (savedListIds) {
      listIds = listIds.concat(savedListIds);
    }

    var thisId = window.location.pathname.replace('/list/', '');

    if (!listIds.includes(thisId)) {
      listIds.push(thisId);
    }
    localStorage.setItem('list_ids', JSON.stringify(listIds));
  }

  function ProtectedRouteWithStoreListIds({ children }) {
    if(user != null) {
      return children;
    } else {
      storeListIdInLocalStorage();

      addToast("We have saved the list for you. Please sign up or log in to add the list to your account.", { appearance: 'info', autoDismiss: true });

      return <Navigate to={ROUTES.SIGNUP} />;
    }
  }

  function ProtectedRoute({ children }) {
    return user != null ? children : <Navigate to={ROUTES.SIGNUP} />;
  }

  function RedirectIfLoggedInRoute({ children }) {
    return user == null ? children : <Navigate to={ROUTES.LISTS} />;
  }

  return (    
    <>
      <userContext.Provider value={{ user }}> 
        <BrowserRouter>
          <Routes>
            {/** Logged OUT pages */ }
            <Route exact path={ROUTES.SIGNUP} element={<RedirectIfLoggedInRoute><SignupScreen/></RedirectIfLoggedInRoute>}/>
            <Route path={ROUTES.LOGIN} element={<RedirectIfLoggedInRoute><LoginScreen/></RedirectIfLoggedInRoute>}/>
            <Route path={ROUTES.RESET_PASSWORD} element={<RedirectIfLoggedInRoute><ResetPasswordScreen/></RedirectIfLoggedInRoute>}/>

            {/** Can be used both in logged in and logged out state */ }
            <Route path={ROUTES.EMAIL_ACTION} element={<FirebaseEmailActionScreen/>}/>

            {/** Logged IN pages */ }
            <Route path={ROUTES.LISTS} element={<ProtectedRoute><ListsScreen/></ProtectedRoute>}/>
            <Route exact path={ROUTES.LIST} element={<ProtectedRouteWithStoreListIds><ListScreen/></ProtectedRouteWithStoreListIds>}/>
            <Route exact path={ROUTES.LIST_SETTINGS} element={<ProtectedRoute><ListSettingsScreen/></ProtectedRoute>              }/>
            <Route path={ROUTES.PROFILE} element={<ProtectedRoute><ProfileScreen/></ProtectedRoute>}/>
          </Routes>
        </BrowserRouter>
      </userContext.Provider>
    </>
	)
}