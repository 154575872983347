import { Timestamp } from 'firebase/firestore'
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSession } from '../../App';
import Spinner from '../../components/Spinner';
import firebase from "../../components/Firebase";
import NewListButton from "../../components/NewListButton";
import MainMenu from '../../components/MainMenu';
import {LIST} from '../../constants/routes';

import './ListsScreen.scss';

function formatDuration(seconds) {
  const units = [
      { label: "y", value: 86400 * 365 },
      { label: "u", value: 86400 * 7 },
      { label: "d", value: 86400 },
      { label: "h", value: 3600 },
      { label: "m", value: 60 },
      { label: "s", value: 1 }
  ];

  for (const unit of units) {
      if (seconds >= unit.value) {
          const count = Math.floor(seconds / unit.value);
          return `${count}${unit.label}`;
      }
  }

  return "0 s"; // Hvis input er 0
};

function ListsScreen() {
  const [isLoading, setIsLoading] = useState(true);
  const [lists, setLists] = useState([]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const user = useSession();

  useEffect(() => {
    var unsubscribe = null;

    try {
      unsubscribe = firebase.getMyLists().onSnapshot(querySnapshot => {
        const readList = [];

        querySnapshot.forEach(doc => {
          const list = doc.data();
          const shared = list.shared_with && list.shared_with.length > 1;

          readList.push({ id: doc.id, name: list.name, shared_with: list.shared_with, shared: shared, updated_at: list.updated_at });
        });

        setIsLoading(false);
        setLists(readList);
      })
    } catch (e) {
      setIsLoading(false);
      setError(true)
      setErrorMessage('Error getting lists' + e);
    }

    const savedListIds = JSON.parse(localStorage.getItem('list_ids'));
    if (savedListIds) {
      savedListIds.forEach((id) => {

        // Ignore all stored list Ids that does not have the correct format.
        if(/^[0-9a-zA-Z]{8}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{12}$/.test(id)) {
          var docRef = firebase.getList(id);
          docRef.get().then(doc => {
            if (doc.exists) {
              var shared_with = doc.data().shared_with;

              if (!shared_with.includes(user.uid)) {
                shared_with.push(user.uid);
                docRef.set({ shared_with: shared_with }, { merge: true });
              }
            }
          });
        }
      })
      localStorage.removeItem('list_ids');
    }

    return function cleanup() {
      unsubscribe && unsubscribe();
    };
  }, [user.uid]);

  if (isLoading) {
    return (
      <Spinner />
    );
  }

  if (error) {
    return (
      <div>
        {errorMessage}
      </div>
    );
  }

  if (lists.length === 0) {
    return (<>
      <MainMenu/>

      <div className="main-content-with-menu">
        <div className="my-lists-container">
          <span className="content">Oh, such emptiness <span role="img" aria-label="ghost-emoji">👻</span> I guess you haven't made any lists yet then. No pressure, but this is a really good time to do it.</span>
          <div className="new-list-container"><NewListButton /></div>
        </div>
      </div>
      </>
    )
  }

  return (<>
    <MainMenu/>

    <div className="main-content-with-menu">
      <div className="my-lists-container">
        <ul>
          {lists.map((list, index) => (
            <li key={index}>
              <Link to={LIST.replace(':id', list.id)}>
              
                <div className="item-container">
                  <span className="list-name">{list.name}</span>
                  
                  <div className="list-footer">
                    <div>
                     {list.shared_with.sort().map((shared_with, shared_with_index) => (
                        list.shared && <img className="shared_with" key={shared_with_index + shared_with} src={"/images/avatars/128x128/avatar_" + shared_with.replace(/\D/g, "").toString().substr(0, 2) + ".png"} alt="Shared with avatars"/>
                     ))}
                    </div>
                    <span className="updated-at">Updated {list.updated_at ? formatDuration((Timestamp.now().seconds - list.updated_at.seconds)) + ' ago' : ' long time ago'}</span>
                  </div>
                </div>
              </Link>
            </li>
          ))}
        </ul>
        <div className="new-list-container"><NewListButton /></div>
      </div>
    </div>
    </>
  );
}

export default ListsScreen;