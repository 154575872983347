import React, {useState} from 'react';
import './NewListButton.scss';
import {useSession} from '../../App';
import firebase from '../Firebase';
import { serverTimestamp } from 'firebase/firestore'
import { v4 as uuidv4 } from 'uuid';
import Button from '../Button';
import { useNavigate } from 'react-router-dom';
import {LIST} from '../../constants/routes';

function NewList() {
  const user = useSession();
  const [working, setWorking] = useState(false);
  const navigate = useNavigate();

  return (
    <Button className="new-list-button" type="button" clickCallback={createNewList} buttonText="Create new list" working={working} />
  )
  
  async function createNewList(event) {
    event.preventDefault();
    setWorking(true);
    var id = uuidv4();

    firebase.createList(id).set(
      { 
        name: "Awesome 💪🏻",
        items: [
          {text: "Congrats! 😀 You got a list. Here are some hints:", id: uuidv4()},
          {text: "1: You can change the name of the list by double clicking the name on the top of this screen.", id: uuidv4()},
          {text: "2: Order items with icon on the left", id: uuidv4()},
          {text: "3: Edit and delete under icon on the right", id: uuidv4()},
          {text: "4: Tap an item to mark it as done", done : true, id: uuidv4()},
          {text: "5: Good luck", id: uuidv4()},

        ],
        formatVersion: 2,
        owner: user.uid,
        shared_with: [user.uid],
        updated_at: serverTimestamp() 
      })
    .then( () => {
      navigate(LIST.replace(":id", id));
    })
    .catch(function (error) {
      console.error("Error writing document: ", error);
      setWorking(false);
    });
	}
}

export default NewList;